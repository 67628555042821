import React from 'react'
import { CssBaseline } from '@mui/material'
import { createTheme, type ThemeOptions, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'

import { typography } from './typography'
import { ComponentsOverrides } from './overrides'
import { palette } from './palette'

const themeOptions: ThemeOptions = {
	palette,
	typography,
	shape: { borderRadius: 12 },
}

export const theme = createTheme(themeOptions)
theme.components = ComponentsOverrides(theme)

type Props = {
	children: React.ReactNode
}

export function ThemeProvider ({ children }: Props) {
	return (
		<MUIThemeProvider theme={theme}>
			<CssBaseline />
			{children}
		</MUIThemeProvider>
	)
}
