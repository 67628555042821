import serverAccess from 'app/utils/axios'
import { dayjs } from 'app/utils/dayjs'
import { type SendOk } from './CommonRepository'
import { type Dayjs } from 'dayjs'
import { type ProductType } from 'app/common/constants/consulting-types'
import { type OrderInfo } from 'app/common/constants/order-types'

export enum ConsultingStatus {
	REQUEST = 'REQUEST', // 예약 요청
	CHANGE_REQUEST = 'CHANGE_REQUEST', // 예약 변경 요청
	CONFIRMED = 'CONFIRMED', // 예약 확정
	FINISHED = 'FINISHED', // 컨설팅 완료

	CANCEL_NO_SHOW = 'CANCEL_NO_SHOW', // 예약 취소(노쇼)
	CANCEL_PRODUCT = 'CANCEL_PRODUCT', // 예약 취소(해지)
	PENDING_CANCEL_PRODUCT = 'PENDING_CANCEL_PRODUCT', // 취소 예정(예약 결제 실패후, 유예기간)
}

export enum ConsultingType {
	STUDY_START = 'STUDY_START', // '공부시작 컨설팅 50분'
	STUDY_DIRECTION = 'STUDY_DIRECTION', // '공부방향 컨설팅 20분'
	DIAGNOSTIC = 'DIAGNOSTIC', // '1:1 공부컨설팅 50분'

	STUDY_START_B2B = 'STUDY_START_B2B', // '[교육기관 전용] 공부시작 컨설팅 50분'
	STUDY_DIRECTION_B2B = 'STUDY_DIRECTION_B2B', // '[교육기관 전용] 공부방향 컨설팅 20분'
	DIAGNOSTIC_B2B = 'DIAGNOSTIC_B2B', // '[교육기관 전용] 1:1 공부 컨설팅 50분'
}

export type User = {
	_id: string
	email: string
	name: string
	grade: string
	school: {
		name: string
	}
}

export type Consultant = User

export type Parent = User

export type Student = {
	parent: Parent | null
	consultant?: string
} & User

export type ConsultData = {
	_id: string
	consultingDate: string // ISO date
	consultingType: ConsultingType
	productType: ProductType
	requestDate: string
	createdAt: string
	status: ConsultingStatus
	consultant: Consultant | null
	reservationNumber: string
	student: Student

	confirmDate: string | null // ISO date
	finishDate: string | null // ISO date
	noShowDate: string | null // ISO date
	toBeConsultingDate?: string // ISO date
	consultantComment: string
	order: string

	isConsultingDate: boolean
}

export type UserConsultingProduct = {
	orderId: string
	productType: ProductType
	consultingType: ConsultingType
	hasValidReservation: boolean
}

type SetReservationInfo = {
	consultingDate: string
	productType: ProductType
	consultingType: ConsultingType
	orderId: string
}

export type CamStudyRoomInfo = {
	result: 'OK'
	camStudyRoomInfo: {
		_id: string
		name: string
		startDate: string
		endDate: string
	} | null
}

export type ConsultingDetail = {
	consultingReservation: ConsultData
	orderInfo: OrderInfo
}

function consultingRepositoryFactory () {
	return {
		getConsultantInfo: () => async (url: string) =>
			await serverAccess.get(url),

		getReservations: () => async (url: string) => {
			const result = await serverAccess
				.get<{ consultingReservations: ConsultData[] }>(url)
				.then(({ data }) => data)

			return result.consultingReservations
		},

		getReservationsByDate: async (date: string) => {
			const { data } = await serverAccess.get<{
				consultingReservations: ConsultData[]
			}>(`/ug/consulting/reservations?date=${date}`)

			return data
		},

		getReservationDetail: async (reservationId: string) => {
			const { data } = await serverAccess.get<ConsultingDetail>(
				`/ug/consulting/reservations/${reservationId}/detail`,
			)

			return data
		},

		getAllUnavailableDates: async (
			consultingType: ConsultingType,
			startDate: Dayjs,
			endDate: Dayjs,
		) => {
			const { data } = await serverAccess.get<{
				unavailableDates: string[]
			}>('/ug/consulting/schedule/overview/unavailable-dates', {
				params: {
					startDate: startDate.format('YYYY-MM-DD'),
					endDate: endDate.format('YYYY-MM-DD'),
					consultingType,
				},
			})

			return data.unavailableDates
		},

		getAllAvailableWorkTimeInfos: async (
			selectedDate: string,
			consultingType: ConsultingType,
		) => {
			return await serverAccess
				.get<{
				availableWorkDateInfo: {
					workTimeInfos: Array<{
						hour: number
						availableConsultantCount: number
					}>
				}
			}>('/ug/consulting/schedule/overview/available-work-times', {
				params: {
					targetDate: dayjs(selectedDate).format('YYYY-MM-DD'),
					consultingType,
				},
			})
				.then(({ data }) => data.availableWorkDateInfo.workTimeInfos)
		},

		getConsultantUnavailableDates: async (data: {
			consultantId: string
			startDate: string // YYYY-MM-DD
			endDate: string // YYYY-MM-DD
			consultingType: ConsultingType
		}) => {
			const { consultantId, ...restInfo } = data

			return await serverAccess.get<{ unavailableDates: string[] }>(
				`/ug/consulting/schedule/overview/unavailable-dates/${data.consultantId}`,
				{ params: restInfo },
			).then(({ data }) => data.unavailableDates)
		},

		getConsultantAvailableWorkTimes: async (data: {
			consultantId: string
			targetDate: string // YYYY-MM-DD
			consultingType: ConsultingType
			exceptedReservationId?: string
		}) => {
			const { consultantId, ...restInfo } = data

			return await serverAccess.get<{ workTimeHours: number[] }>(
				`/ug/consulting/schedule/overview/available-consultants/${consultantId}/work-times`,
				{ params: restInfo },
			).then(({ data }) => data.workTimeHours)
		},
		getConsultingRoomEnterInfo: () => async (url: string) => {
			return await serverAccess
				.get<{ roomLink: string, accessToken: string, roomId: string }>(url)
				.then(({ data }) => data)
		},
		setReservation: async (data: SetReservationInfo) => {
			return await serverAccess.post('/ug/consulting/reservations', data)
		},
		getChildrenList: async (searchWord: string) => {
			return await serverAccess.get(
				`/ug/consulting/students?searchWord=${searchWord}`,
			)
		},
		requestConnect: async ({ studentId }: { studentId: string }) => {
			return await serverAccess.post(
				'/ug/consulting/connection/request-connect',
				{ studentId },
			)
		},
		cancelOnNoShow: async (reservationId: string, cancelReason: string) => {
			return await serverAccess.put(
				`/ug/consulting/reservations/${reservationId}/cancel/no-show`,
				{
					cancelReason,
				},
			)
		},
		changeReservation: async ({
			reservationId,
			toBeConsultingDate,
		}: {
			reservationId: string
			toBeConsultingDate: string
		}): Promise<{ data: SendOk, status: number }> => {
			return await serverAccess.put(
				`/ug/consulting/reservations/${reservationId}/change`,
				{
					toBeConsultingDate,
				},
			)
		},
		changeRequestConfirmReservation: async (reservationId: string) => {
			return await serverAccess.put(
				`/ug/consulting/reservations/${reservationId}/confirm`,
			)
		},
		saveExpoPushToken: async (token: string) => {
			return await serverAccess.post('/ug/consulting/expo-token', {
				token,
			})
		},

		getUserConsultingProductInfo: async () => {
			return await serverAccess.get<{ productInfo: UserConsultingProduct | null }>(
				'/ug/consulting/users/my-product',
			).then(({ data }) => data.productInfo)
		},
		getCamStudyRoomInfo: async () => {
			const { data } = await serverAccess.get<CamStudyRoomInfo>(
				'/ug/consulting/cam-study/info',
			)

			return data
		},
		getCamStudyRoomEnterInfo: async () => {
			const { data } = await serverAccess.get(
				'/ug/consulting/cam-study/room-link',
			)

			return data
		},
		checkConflictStudyRoomEnter: async () => {
			const { data } = await serverAccess.get<{
				result: 'OK'
				isEnteredUser: boolean
			}>('/ug/consulting/cam-study/is-entered-user')

			return data.isEnteredUser
		},
		loggingTerminateInfoApi: async ({
			accessToken,
			roomId,
			terminateReason,
			roomType,
		}: {
			accessToken: string
			roomId: string
			terminateReason: string
			roomType: 'consulting' | 'camStudy'
		}) => {
			await serverAccess.post(
				'/ug/consulting/page-call/log/terminate-info',
				{
					accessToken,
					roomId,
					terminateReason,
					roomType,
				},
			)
		},
		saveShippingInfo: async ({
			name,
			phoneNumber,
			zipCode,
			address,
			detailedAddress,
			request,
		}: {
			name: string
			phoneNumber: string
			zipCode: string
			address: string
			detailedAddress: string
			request: string
		}) => {
			await serverAccess.post(
				'/ug/consulting/google-sheet/address',
				{
					name,
					phoneNumber,
					zipCode,
					address,
					detailedAddress,
					request,
				},
			)
		},
	}
}

const consultingRepository = consultingRepositoryFactory()

export default consultingRepository
