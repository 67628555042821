import originDayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import ko from 'dayjs/locale/ko'
import minMax from 'dayjs/plugin/minMax'

originDayjs.extend(utc)
originDayjs.extend(timezone)
originDayjs.extend(isBetween)
originDayjs.extend(isSameOrBefore)
originDayjs.extend(isSameOrAfter)
originDayjs.tz.setDefault('Asia/Seoul')
originDayjs.locale({
	...ko,
	weekStart: 1,
})

originDayjs.extend(minMax)

// dayjs() 와 같이 사용할 때, 항상 .tz() 를 사용하여 timezone이 항상 서울로 고정되도록.
const tz = (...param: any[]) => originDayjs(...param).tz()

// dayjs.xx 와 같이 사용하는 케이스에 대한 대처
export const dayjs = new Proxy(tz, {
	get (_, prop) {
		// @ts-ignore
		const value = originDayjs[prop]

		return typeof value === 'function' ? value.bind(originDayjs) : value // (*)
	},
}) as unknown as typeof originDayjs
