import authRepository from 'app/api/AuthRepository'
import { LocalStorage } from 'app/utils/storage'
import { useSetRecoilState } from 'recoil'
import { pushTokenState } from 'app/common/recoil/pushTokenAtom'
import consultingRepository from 'app/api/ConsultingRepository'
import useSWRMutation from 'swr/mutation'
import { type AxiosError } from 'axios'
import { Toast } from 'native-base'
import { useRouter } from 'next/router'
import { mutate } from 'swr'
import { API_USER } from 'app/common/constants/swr-key'
import { useCallback } from 'react'

import { PATH, PATH_OBJ } from '@bridge/core/constants/route-path'
import { getErrorMessage } from '@bridge/core/util/error'

const useAuth = () => {
	const { push } = useRouter()
	const pushToken = useSetRecoilState(pushTokenState)

	const { trigger: loginTrigger } = useSWRMutation<any, AxiosError, string, { email: string, password: string }>('/user-login',
		async (_key, { arg }) => {
			const { email, password } = arg

			return await authRepository.signIn({
				username: email,
				password,
			})
		}, {
			onError: (error) => {
				const errorMessage = error.response?.status === 401
					? '이메일 또는 비밀번호가 틀렸어요'
					: getErrorMessage(error)

				Toast.show({ description: errorMessage })
			},
		})

	const loginOnSuccess = async (data: any) => {
		LocalStorage.setIdToken(data.idToken.jwtToken)
		LocalStorage.setAccessToken(data.accessToken.jwtToken)
		LocalStorage.setRefreshToken(data.refreshToken.token)
		await mutate(API_USER)

		savePushToken().catch(console.error)
	}

	const login = async (email: string, password: string) => {
		await loginTrigger({ email, password }, {
			onSuccess: async ({ data }) => {
				await loginOnSuccess(data)

				if (data.idToken.payload.profile === 'parent') {
					await push('/parent')

					return
				}

				const returnUrl = new URLSearchParams(window.location.search).get('return-url')
				const path = returnUrl ?? PATH_OBJ.TAB_BAR_HOME

				await push(path)
			},
		})
	}

	const signUpLogin = async (email: string, password: string) => {
		await loginTrigger({ email, password }, {
			onSuccess: async ({ data }) => {
				await loginOnSuccess(data)
				await push(PATH.SIGN_UP_COMPLETE_EMAIL)
			},
		})
	}

	const logout = useCallback(async ({ revalidate = true } = {}) => {
		LocalStorage.clearAuth()

		// swr cache clear
		await mutate(
			_key => true,
			undefined,
			{ revalidate },
		)
	}, [])

	async function savePushToken () {
		if (!pushToken) return

		try {
			await consultingRepository.saveExpoPushToken(pushToken as any)
		} catch (error) {
			console.error(error)
		}
	}

	return {
		login,
		logout,
		signUpLogin,
	}
}

export default useAuth
