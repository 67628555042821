/* eslint-disable @typescript-eslint/restrict-template-expressions */
import Constants from 'expo-constants'

const { manifest } = Constants

// TODO: 환경변수로 관리(expo는 서버 호스트 URL을 그대로 사용, next는 proxy로 api에 이름만 변경해서 사용 가능)
const buildComputerIP = manifest?.debuggerHost?.split(':')[0] ?? ''

type HostURLType = {
	[index: string]: string
	local: string
	dev: string
	stg: string
	prd: string
}

const HOST_URL: HostURLType = {
	local: `http://${buildComputerIP || 'localhost'}:${process.env.PORT ?? '3000'}`,
	// TODO: 웹 쪽 CORS 이슈로 인해 상대경로로 변경했습니다.
	// 추후 네이티브쪽을 고려해서 웹만 상대경로로 설정되도록 분기해야됨
	dev: '',
	stg: '',
	prd: '',
}

const API_END_POINT = {
	HOST: HOST_URL[`${process.env.NEXT_PUBLIC_STAGE}`],
	AUTH: 'http://localhost:8901',
	DIAGNOSTIC: 'http://localhost:8902',
	ADMIN: 'http://localhost:8903',
	NOTIFICATION: 'http://localhost:8904',
	PAYMENT: 'http://localhost:8905',
	CONSULTING: 'http://localhost:8906',
	STUDY: 'http://localhost:8907',
}

export default API_END_POINT
