import React, { type PropsWithChildren } from 'react'
import { Stack } from '@bridge/ui'
import colors from 'app/colors'
import useWebDimensions from 'app/utils/useWebDimensions'

type SafeAreaFlexProps = PropsWithChildren<{
	hasTabBar?: boolean
}>

const TabBarHeight = 64

function SafeAreaFlex ({ hasTabBar = false, children }: SafeAreaFlexProps) {
	const dimension = useWebDimensions()

	const headerHeight = 50
	const bottomOffset = hasTabBar ? TabBarHeight : 0

	return (
		<Stack
			bgcolor={colors.white50}
			marginBottom={hasTabBar ? `${TabBarHeight}px` : 0}
			minHeight={dimension.height - (headerHeight + bottomOffset)}
		>
			{children}
		</Stack>
	)
}

export default SafeAreaFlex
